<template>
  <div>
    <b-card title="CBP Actions">
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="party.InACE === 'Yes' && party.InACE !== 'Pending'&& (party.IsImporter === true || party.IsConsignee === true || party.IsManufacturer === true )"
            v-b-tooltip.hover="'Update in CBP System'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="UpdatePartyInACE()"
          ><feather-icon
             icon="UploadCloudIcon"
             class="mr-50"
           />
            <span class="align-middle">Update Importer</span></b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Create in CBP System'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SavePartyInACE()"
          ><feather-icon
             icon="UploadCloudIcon"
             class="mr-50"
           />
            <span class="align-middle">File Importer</span></b-button>

        </div>
      </b-button-toolbar>
    </b-card>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <h4>{{ party.PartyFullName }}</h4>
          <h6>Updated: {{ party.LastUpdatedDate }}</h6>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Delete in Database (Does not update in CBP system)'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteParty()"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="UpdateParty()"
          >
            <font-awesome-icon
              :icon="['far', 'save']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="12">
          <b-tabs>
            <b-tab
              ref="DetailTab"
              title="Detail"
            >
              <b-row>
                <b-col md="12">
                  <validation-observer
                    ref="detailRules"
                    tag="form"
                  >
                    <b-row>
                      <b-col
                        md="12"
                        style="margin: auto"
                      >
                        <b-form-checkbox v-model="party.IsActive">
                          Active
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Customer"
                            rules="required"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Customer:</b></i></label>
                              <v-select
                                id="CustomerOption"
                                v-model="party.CustomerId"
                                :options="CustomerOptions"
                                label="name"
                                placeholder="Select a Customer"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Company Type"
                            rules="required"
                          >
                            <b-form-group :state="errors.length > 0 ? false:null">
                              <label class="required"><i><b>Company Type:</b></i></label>
                              <v-select
                                id="PartyTypeOption"
                                v-model="party.PartyType"
                                :options="PartyTypeOptions"
                                label="text"
                                placeholder="Select a type"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Party Name"
                            rules="required|max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Name:</b></i></label>
                              <b-form-input
                                id="PartyName"
                                v-model="party.PartyName"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Party Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Full Legal Name"
                            rules="required|max:100"
                          >
                            <label class="required"><i><b>Full Legal Name:</b></i></label>
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <b-form-input
                                id="LegalName"
                                v-model="party.PartyFullName"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Legal Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="DBA"
                            rules="max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>DBA:</b></i></label>
                              <b-form-input
                                id="DBA"
                                v-model="party.DBA"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="DBA"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Aka"
                            rules="max:100"
                          >
                            <label><i><b>Aka:</b></i></label>
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <b-form-input
                                id="Aka"
                                v-model="party.Aka"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Aka"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Country"
                            rules="required"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Country:</b></i></label>
                              <v-select
                                id="PartyCountryOption"
                                v-model="party.PartyCountry"
                                :options="CountryOptions"
                                label="country"
                                placeholder="Select a Country"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Parent Company"
                            rules="max:32"
                          >
                            <label><i><b>Parent Company:</b></i></label>
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <b-form-input
                                id="ParentCompany"
                                v-model="party.ParentCompany"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Parent Company"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              ref="TypesTab"
              title="Types"
            >
              <b-row>
                <b-col md="12">
                  <validation-observer
                    ref="typeRules"
                    tag="form"
                  >
                    <b-row>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsImporter">
                          Importer
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsConsignee">
                          Consignee
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsShipTo">
                          Ship To
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsBuyer">
                          Buyer
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsSeller">
                          Seller
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsManufacturer">
                          Manufacturer
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsConsolidator">
                          Consolidator
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsContainerStuffing">
                          Container Stuffing Location
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsBookingParty">
                          Booking Party
                        </b-form-checkbox>
                      </b-col>
                      <b-col md="3">
                        <b-form-checkbox v-model="party.IsNotifyParty">
                          Notify Party
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>

            </b-tab>
            <b-tab
              ref="IdentifiersTab"
              title="Identifiers"
            >
              <b-row>
                <b-col md="12">
                  <validation-observer
                    ref="identifierRules"
                    tag="form"
                  >
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Importer Number"
                            :rules="`${party.IsImporter ? 'required|max:12' : 'max:12'}`"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label :class="`${party.IsImporter ? 'required' : ''}`"><i><b>Importer #:</b></i></label>
                              <b-form-input
                                id="ImporterNumber"
                                v-model="party.ImporterNumber"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Importer Number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="EIN"
                            :rules="`${(party.IsImporter | party.IsConsignee) ? 'required|max:12' : 'max:12'}`"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label :class="`${party.IsImporter | party.IsConsignee ? 'required' : ''}`"><i><b>EIN:</b></i></label>
                              <b-form-input
                                id="EIN"
                                v-model="party.EIN"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="EIN"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="CBP Assigned"
                            rules="max:12"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>CBP Assigned #:</b></i></label>
                              <b-form-input
                                id="CBPAssigned"
                                v-model="party.CBPAssigned"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="CBP Assigned"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="MID Number"
                            :rules="`${party.IsManufacturer ? 'required|max:15|min:15': 'max:15|min:15'}`"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label :class="`${party.IsManufacturer ? 'required': ''}`"><i><b>MID #:</b></i></label>
                              <b-form-input
                                id="MIDNumber"
                                v-model="party.MIDNumber"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="MID Number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="SCAC"
                            rules="max:4|min:4"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>SCAC:</b></i></label>
                              <b-form-input
                                id="SCAC"
                                v-model="party.SCAC"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="SCAC"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="SSN"
                            rules="max:11|min:11"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>SSN:</b></i></label>
                              <b-form-input
                                id="SSN"
                                v-model="party.SSN"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="SSN"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Passport Number"
                            rules="max:12"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Passport #:</b></i></label>
                              <b-form-input
                                id="PassportNumber"
                                v-model="party.PassportNumber"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Passport Number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="FIRMS Number"
                            rules="max:4|min:4"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>FIRMS:</b></i></label>
                              <b-form-input
                                id="FIRMS"
                                v-model="party.FIRMS"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="FIRMS"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="DUMS Number"
                            rules="max:9|min:9"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>DUNS #:</b></i></label>
                              <b-form-input
                                id="DUNS"
                                v-model="party.DUNS"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="DUNS Number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              ref="AddressesTab"
              title="Addresses"
            >
              <b-row>
                <b-col md="12">
                  <validation-observer
                    ref="addressRules"
                    tag="form"
                  >
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing Address"
                            rules="required|max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Mailing Address 1:</b></i></label>
                              <b-form-input
                                id="MailingAddress"
                                v-model="party.MailingAddress"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Mailing Address1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing Address 2"
                            rules="max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Mailing Address 2:</b></i></label>
                              <b-form-input
                                id="MailingAddress2"
                                v-model="party.MailingAddress2"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Mailing Address 2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing City"
                            rules="required|max:23"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Mailing City:</b></i></label>
                              <b-form-input
                                id="MailingCity"
                                v-model="party.MailingCity"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Mailing City"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing State"
                            rules="max:2"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Mailing State:</b></i></label>
                              <b-form-input
                                id="MailingState"
                                v-model="party.MailingState"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Mailing State"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing Country"
                            rules="required"
                          >
                            <b-form-group :state="errors.length > 0 ? false:null">
                              <label class="required"><i><b>Mailing Country:</b></i></label>
                              <v-select
                                id="MailingCountry"
                                v-model="party.MailingCountry"
                                :options="CountryOptions"
                                label="country"
                                placeholder="Select a country"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailing Zip"
                            rules="max:9"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Mailing Zip:</b></i></label>
                              <b-form-input
                                id="MailingZip"
                                v-model="party.MailingZip"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Mailing Zip"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical Address"
                            rules="required|max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Physical Address 1:</b></i></label>
                              <b-form-input
                                id="PhysicalAddress"
                                v-model="party.PhysicalAddress"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Physical Address1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical Address 2"
                            rules="max:32"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Physical Address 2:</b></i></label>
                              <b-form-input
                                id="PhysicalAddress2"
                                v-model="party.PhysicalAddress2"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Physical Address 2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical City"
                            rules="required|max:23"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label class="required"><i><b>Physical City:</b></i></label>
                              <b-form-input
                                id="PhysicalCity"
                                v-model="party.PhysicalCity"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Physical City"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical State"
                            rules="max:2"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Physical State:</b></i></label>
                              <b-form-input
                                id="PhysicalState"
                                v-model="party.PhysicalState"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Physical State"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical Country"
                            rules="required"
                          >
                            <b-form-group :state="errors.length > 0 ? false:null">
                              <label class="required"><i><b>Physical Country:</b></i></label>
                              <v-select
                                id="PhysicalCountry"
                                v-model="party.PhysicalCountry"
                                :options="CountryOptions"
                                label="country"
                                placeholder="Select a country"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Physical Zip"
                            rules="max:9"
                          >
                            <b-form-group
                              :state="errors.length > 0 ? false:null"
                            >
                              <label><i><b>Physical Zip:</b></i></label>
                              <b-form-input
                                id="PhysicalZip"
                                v-model="party.PhysicalZip"
                                v-uppercase
                                :state="errors.length > 0 ? false:null"
                                placeholder="Physical Zip"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>

      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  data() {
    return {
      required,
      party: {
        InACE: 'No',
        CustomerId: null,
        PartyID: this.$route.params.partyId,
        AddedBy: '',
        AddedDate: '',
        Aka: '',
        CBPAssigned: '',
        DBA: '',
        EIN: '',
        FIRMS: '',
        DUNS: '',
        PartyFullName: '',
        ImporterNumber: '',
        LastUpdatedBy: '',
        LastUpdatedDate: '',
        MailingAddress: '',
        MailingAddress2: '',
        MailingCity: '',
        MailingCountry: null,
        MailingState: '',
        MailingZip: '',
        MIDNumber: '',
        PartyName: '',
        ParentCompany: '',
        PartyCountry: null,
        PartyType: null,
        PassportNumber: '',
        PhysicalAddress: '',
        PhysicalAddress2: '',
        PhysicalCity: '',
        PhysicalCountry: null,
        PhysicalState: '',
        PhysicalZip: '',
        SCAC: '',
        SSN: '',
        IsBookingParty: false,
        IsBuyer: false,
        IsConsignee: false,
        IsConsolidator: false,
        IsContainerStuffing: false,
        IsImporter: false,
        IsManufacturer: false,
        IsNotifyParty: false,
        IsSeller: false,
        IsShipTo: false,
        IsActive: false,
      },
      PartyTypeOptions: [
        { text: 'Corporation', value: 'C' },
        { text: 'Partnership', value: 'P' },
        { text: 'Individual', value: 'I' },
        { text: 'Sole Proprietor', value: 'S' },
        { text: 'Foreign Government', value: 'F' },
        { text: 'US Government', value: 'G' },
        { text: 'State Government', value: 'L' },
        { text: 'LLC', value: 'K' },

      ],
      CountryOptions: [],
      CustomerOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  mounted() {

  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCountryList(), this.LoadCustomerList()]).then(() => {
        this.LoadPartyContent(this.party.PartyID).then(() => {
          this.LoadingEnd()
        })
      })
    },
    LoadPartyContent(partyid) {
      return axios.get(`/abi/party/${partyid}/detail`).then(resp => {
        const response = resp.data
        const respdata = response[0]
        this.party.CustomerId = this.CustomerOptions.find(option => option.id === respdata.customerId)
        this.party.PartyName = respdata.name
        this.party.IsActive = respdata.active
        this.party.InACE = respdata.inACE
        this.party.PartyID = respdata.partyId
        this.party.AddedBy = respdata.addedBy
        this.party.AddedDate = respdata.addedDate
        this.party.Aka = respdata.aka
        this.party.CBPAssigned = respdata.cbpAssigned
        this.party.DBA = respdata.dba
        this.party.EIN = respdata.ein
        this.party.PartyFullName = respdata.fullLegalName
        this.party.ImporterNumber = respdata.importerNumber
        this.party.LastUpdatedBy = respdata.lastUpdatedBy
        this.party.LastUpdatedDate = respdata.lastUpdatedDate
        this.party.MailingAddress = respdata.mailingAddress
        this.party.MailingAddress2 = respdata.mailingAddress2
        this.party.MailingCity = respdata.mailingCity
        this.party.MailingCountry = this.CountryOptions.find(option => option.countryCode === respdata.mailingCountry)
        this.party.MailingState = respdata.mailingState
        this.party.MailingZip = respdata.mailingZip
        this.party.MIDNumber = respdata.midNumber
        this.party.ParentCompany = respdata.parentCompany
        this.party.PartyCountry = this.CountryOptions.find(option => option.countryCode === respdata.partyCountry)
        this.party.PartyType = this.PartyTypeOptions.find(option => option.value === respdata.partyType)
        this.party.PassportNumber = respdata.passportNumber
        this.party.PhysicalAddress = respdata.physicalAddress
        this.party.PhysicalAddress2 = respdata.physicalAddress2
        this.party.PhysicalCity = respdata.physicalCity
        this.party.PhysicalCountry = this.CountryOptions.find(option => option.countryCode === respdata.physicalCountry)
        this.party.PhysicalState = respdata.physicalState
        this.party.PhysicalZip = respdata.physicalZip
        this.party.SCAC = respdata.scac
        this.party.SSN = respdata.ssn
        this.party.IsBookingParty = respdata.isBookingParty
        this.party.IsBuyer = respdata.isBuyer
        this.party.IsConsignee = respdata.isConsignee
        this.party.IsConsolidator = respdata.isConsolidator
        this.party.IsContainerStuffing = respdata.isContainerStuffing
        this.party.IsImporter = respdata.isImporter
        this.party.IsManufacturer = respdata.isManufacturer
        this.party.IsNotifyParty = respdata.isNotifyParty
        this.party.IsSeller = respdata.isSeller
        this.party.IsShipTo = respdata.isShipTo
        this.party.FIRMS = respdata.firms
        this.party.DUNS = respdata.duns
      })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    UpdateParty() {
      this.$refs.detailRules.validate().then(success => {
        if (success) {
          this.$refs.typeRules.validate().then(success1 => {
            if (success1) {
              this.$refs.identifierRules.validate().then(success2 => {
                if (success2) {
                  this.$refs.addressRules.validate().then(success3 => {
                    if (success3) {
                      this.LoadingStart()
                      const PartyDetailModel = {
                        CustomerId: this.party.CustomerId.id,
                        Active: this.party.IsActive,
                        Name: this.party.PartyName,
                        FullLegalName: this.party.PartyFullName,
                        DBA: this.party.DBA,
                        AKA: this.party.Aka,
                        PartyCountry: this.party.PartyCountry.countryCode,
                        ParentCompany: this.party.ParentCompany,
                        ImporterNumber: this.party.ImporterNumber,
                        MIDNumber: this.party.MIDNumber,
                        PartyType: this.party.PartyType.value,
                        EIN: this.party.EIN,
                        CBPAssigned: this.party.CBPAssigned,
                        SSN: this.party.SSN,
                        PassportNumber: this.party.PassportNumber,
                        SCAC: this.party.SCAC,
                        MailingAddress: this.party.MailingAddress,
                        MailingAddress2: this.party.MailingAddress2,
                        MailingCity: this.party.MailingCity,
                        MailingState: this.party.MailingState,
                        MailingCountry: this.party.MailingCountry.countryCode,
                        MailingZip: this.party.MailingZip,
                        PhysicalAddress: this.party.PhysicalAddress,
                        PhysicalAddress2: this.party.PhysicalAddress2,
                        PhysicalCity: this.party.PhysicalCity,
                        PhysicalState: this.party.PhysicalState,
                        PhysicalCountry: this.party.PhysicalCountry.countryCode,
                        PhysicalZip: this.party.PhysicalZip,
                        IsManufacturer: this.party.IsManufacturer,
                        IsSeller: this.party.IsSeller,
                        IsBuyer: this.party.IsBuyer,
                        IsShipTo: this.party.IsShipTo,
                        IsContainerStuffing: this.party.IsContainerStuffing,
                        IsConsolidator: this.party.IsConsolidator,
                        IsConsignee: this.party.IsConsignee,
                        IsImporter: this.party.IsImporter,
                        IsBookingParty: this.party.IsBookingParty,
                        IsNotifyParty: this.party.IsNotifyParty,
                        FIRMS: this.party.FIRMS,
                        DUNS: this.party.DUNS,
                      }
                      axios.put(`/abi/party/${this.party.PartyID}/detail`, PartyDetailModel)
                        .then(() => {
                          this.LoadingEnd()
                          this.DisplaySuccess('Party data has been saved.')
                        })
                        .catch(e => {
                          this.LoadingEnd()
                          this.DisplayError('Party data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
                        })
                    } else {
                      this.$refs.AddressesTab.activate()
                    }
                  })
                } else {
                  this.$refs.IdentifiersTab.activate()
                }
              })
            } else {
              this.$refs.TypesTab.activate()
            }
          })
        } else {
          this.$refs.DetailTab.activate()
        }
      })
    },
    DeleteParty() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/abi/party/${this.party.PartyID}/detail`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccessRemove('Party data has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/abi/parties')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove party data.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayErrorRemove('Cannot remove party data.', 'Please try again later.')
        }
      })
    },
  },
}

</script>
